/* eslint-disable import/named */
import { API_EXODUS_URL } from 'constants/api';
import requestExodusConfig from 'config/requestExodus';
import { APIPromiseResponse } from '../../../@types/api-response';
import { Suggestions, TrendingUserResponse } from '../../../@types/friend';
import { TrendingTimeFrame } from 'features/discover-people/types';

const requestExodus = requestExodusConfig();

const suggestionURL = `${API_EXODUS_URL}/friend-discovery/suggestions`;
const FRIEND_DISCOVERY = `${API_EXODUS_URL}/friend-discovery`;
// const FRIENDS_URL = '/friends';

export type UserSuggestFilter =
  | 'FILTER_UNSPECIFIED'
  | 'FILTER_ALL'
  | 'FILTER_STREAM'
  | 'FILTER_ONBOARDING';

interface GetSuggestFriends {
  limit: number;
  page: number;
  filter?: UserSuggestFilter;
}

interface GetFolloweBy {
  userid: number;
  page?: number;
  limit?: number;
  keyword?: string;
}

const getSuggestFriends = (
  config: GetSuggestFriends,
): APIPromiseResponse<Suggestions[]> => {
  const { limit, page, filter = 0 } = config;
  return requestExodus.get(`${suggestionURL}/query`, {
    params: { limit, page, filter },
  });
};

const getSuggestFriendsContacts = (
  limit: number,
  page: number,
): APIPromiseResponse<{ found: [] }> =>
  requestExodus.get(`${suggestionURL}/contacts`, {
    params: { limit, page },
  });

const getBlacklistStatus = (): APIPromiseResponse<any> =>
  requestExodus.get(`${FRIEND_DISCOVERY}/contacts/discoverable`);

const updateBlacklistStatus = (): APIPromiseResponse<any> =>
  requestExodus.post(`${FRIEND_DISCOVERY}/contacts/discoverable`);

const getContactSyncStatus = (): APIPromiseResponse<any> =>
  requestExodus.get(`${API_EXODUS_URL}/friend-discovery/contacts/status`);

const getFollowedBy = (params: GetFolloweBy): APIPromiseResponse<any> =>
  requestExodus.get(`${FRIEND_DISCOVERY}/followed-by`, { params });

const getTrending = (
  timeframe: TrendingTimeFrame = TrendingTimeFrame.ALL,
  limit = 100,
  cursor?: string,
): APIPromiseResponse<TrendingUserResponse> =>
  requestExodus.get('user/v2/discovery/trending', {
    params: {
      time_frame: timeframe,
      limit,
      cursor,
    },
  });

export default {
  getSuggestFriends,
  getSuggestFriendsContacts,
  getBlacklistStatus,
  updateBlacklistStatus,
  getContactSyncStatus,
  getFollowedBy,
  getTrending,
};
