export enum LoginType {
  Social = 'social',
  Securities = 'securities',
}

export enum OtpChannel {
  Unspecified = 'CHANNEL_UNSPECIFIED',
  Sms = 'CHANNEL_SMS',
  WhatsApp = 'CHANNEL_WHATSAPP',
}
