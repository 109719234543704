import create from 'zustand';
import produce, { Draft } from 'immer';
import { persist } from 'zustand/middleware';
import auth from 'utils/auth';
import { PaymentMethod } from 'global/Securities/types/transaction';
import { LoginType } from 'typings/auth';
import { omitObject, pickObject } from 'utils/objectOperations';
import { FeatureFlagKeys } from 'lib/flipt/constants/flagKeys';
import { EvaluationResult } from 'lib/flipt/types/store';
import { RealizedFilterEnum } from 'features/trade/components/History/types';
import { PortfolioTypeEnum } from 'features/trade/components/Portfolio/Filter/types';

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export interface UIConfigState {
  // Transaction Modal
  transactionPaymentMethod: PaymentMethod;
  tradingLimitTooltip: boolean;
  tradingLimitTnc: boolean;
  bankAmendRejectedDateResponse?: Date;

  // Day Trade
  dayTradeTnc: boolean;
  dayTradeTncShown: boolean;
  dayTradeSubAccountActive: boolean;

  // Multi Portfolio
  multiPortfolioOnboarding: boolean;

  // Remisier / Community States
  remisier?: {
    isLeader: boolean;
    isCommunityAccessGranted: boolean;
  };

  // Share Trade
  shareTrade: {
    isBannerShown: boolean;
    isTransactionBannerShown: boolean;
    isInfoModalShown: boolean;
  };

  // History - Realized filter
  realized: {
    [RealizedFilterEnum.BOND_COUPON]: boolean;
    [RealizedFilterEnum.BOND_REALIZED]: boolean;
    [RealizedFilterEnum.STOCK_DIVIDEND]: boolean;
    [RealizedFilterEnum.STOCK_REALIZED]: boolean;
  };

  // feature flags
  featureFlags:
    | Record<FeatureFlagKeys, EvaluationResult>
    | Record<string, never>;

  // Portfolio Filter
  portfolioFilter: PortfolioTypeEnum;
}

type Recipe<T extends keyof UIConfigState> = (
  recipe: Draft<RecursivePartial<UIConfigState[T]>>,
) => void;

type ResetRules = Record<LoginType, (keyof UIConfigState)[]>;

interface State {
  userConfig: Record<string, Partial<UIConfigState>>;
}

interface Action {
  setUIValue<T extends keyof Partial<UIConfigState>>(
    key: T,
    value: UIConfigState[T] | Recipe<T>,
    userId?: string,
  ): void;
  getUIValue<T extends keyof UIConfigState>(
    key: T,
    userId?: string,
  ): Partial<UIConfigState>[T];
  resetUIValue(loginType: LoginType, userId?: string): void;
}

export const resetRules: ResetRules = {
  // List item that should not deleted on social logout
  [LoginType.Social]: [
    'tradingLimitTooltip',
    'tradingLimitTnc',

    // Day Trade
    'dayTradeTnc',
    'dayTradeTncShown',
  ],
  // List item that should be deleted on securities logout
  [LoginType.Securities]: ['featureFlags'],
};

const initialState: State = {
  userConfig: {},
};

const useUIConfigStore = create(
  persist<State & Action>(
    (set, get) => ({
      ...initialState,
      setUIValue: (
        key,
        value,
        userId = (auth.getUserAccess() as { id?: string })?.id,
      ) => {
        set(
          produce<State>((state) => {
            if (!state.userConfig[userId]) state.userConfig[userId] = {};

            if (typeof value === 'function') {
              if (!state.userConfig[userId][key]) {
                state.userConfig[userId] = {
                  ...state.userConfig[userId],
                  [key]: {},
                };
              }
              value(state.userConfig[userId][key]);
              return;
            }

            state.userConfig[userId][key] = value;
          }),
        );
      },
      getUIValue: (
        key,
        userId = (auth.getUserAccess() as { id?: string })?.id,
      ) => get().userConfig[userId]?.[key],
      resetUIValue: (
        loginType,
        userId = (auth.getUserAccess() as { id?: string })?.id,
      ) => {
        const resetSocial = () => {
          set(
            produce<State>((state) => {
              state.userConfig[userId] = pickObject(
                state.userConfig[userId],
                resetRules[LoginType.Social],
              );
            }),
          );
        };

        const resetSecurities = () => {
          set(
            produce<State>((state) => {
              state.userConfig[userId] = omitObject(
                state.userConfig[userId],
                resetRules[LoginType.Securities],
              );
            }),
          );
        };

        const resetActions = {
          [LoginType.Social]: resetSocial,
          [LoginType.Securities]: resetSecurities,
        };

        resetActions[loginType]();
      },
    }),
    {
      name: 'UIConfigStore',
    },
  ),
);

export default useUIConfigStore;
