export const pickObject = <T, K extends keyof T>(
  obj: T,
  keys: K[],
): Pick<T, K> => {
  const pickedObject: Partial<T> = {};
  keys.forEach((key) => {
    pickedObject[key] = obj?.[key];
  });
  return pickedObject as Pick<T, K>;
};

export const omitObject = <T, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> => {
  const omittedObject: T = { ...obj };
  keys.forEach((key) => delete omittedObject[key]);
  return omittedObject as Omit<T, K>;
};
