/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
import {
  AWS_AVATAR_BUCKET,
  AWS_IMAGES_BUCKET,
  AWS_TRADE_REG_BUCKET,
} from 'constants/api';
import ERROR_MESSAGE from 'constants/errorMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

import streamApi from 'lib/api/stream';

export function convertToSlug(text) {
  if (!text) return text;
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-$/, ''); // Trim - from end of text
}

export const generateId = () => {
  // @ts-ignore
  const crypto = window?.crypto || window?.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  const id = array[0];
  return id;
};

export const formatUploadFileName = (file) => {
  // eslint-disable-next-line no-bitwise
  const extension = file.slice(((file.lastIndexOf('.') - 1) >>> 0) + 2); // Returning only file extension, safeguarding from 'filename' or '.filename'
  const filename = file.substr(0, file.lastIndexOf('.'));
  const randomId = generateId();
  const d = new Date();
  const currDate = String(`00${d.getDate()}`).slice(-2); // Get today's date with leading zero
  const currMonth = String(`00${d.getMonth() + 1}`).slice(-2); // Get today's month with leading zero
  const currYear = d.getFullYear();
  return `${convertToSlug(
    `${filename} ${randomId}${currYear}${currMonth}${currDate}`,
  )}.${extension}`;
};

/**
 * Creating upload file promise object
 * @param {Object} file
 * @param {String} token
 * @returns {Promise}
 */
const createUploadPromise = (file, token, bucket = 'stockbit') => {
  /**
   * If an old url file is being passed, return the passed url instead.
   * The old file validation process works by checking if the filename sent is
   * a string and an url with http or https
   */
  if (typeof file === 'string' && file.indexOf('http') !== -1) {
    return Promise.resolve({ data: { url: file } });
  }

  // Generate unique filename
  const fileName =
    file instanceof File ? file.name : String(file.split('/').pop());
  const newFileName = formatUploadFileName(fileName);

  const fd = new FormData();
  let formerFile = null;
  // eslint-disable-next-line no-underscore-dangle
  if (file._file) formerFile = file._file;

  fd.append('key', newFileName);
  fd.append('AWSAccessKeyId', token.AWSAccessKeyId);
  fd.append('acl', 'public-read');
  fd.append('success_action_redirect', token.success_action_redirect);
  fd.append('policy', token.policy);
  fd.append('signature', token.signature);
  fd.append('Content-Type', '');

  if (!formerFile) {
    fd.append('file', file);
  } else {
    fd.append('file', formerFile);
  }
  let awsBucket = AWS_IMAGES_BUCKET;
  if (bucket === 'simasreg') awsBucket = AWS_TRADE_REG_BUCKET;
  if (bucket === 'avatar') awsBucket = AWS_AVATAR_BUCKET;

  return fetch(awsBucket, {
    method: 'POST',
    cache: 'default',
    body: fd,
    mode: 'cors',
  })
    .then((response) => response.json())
    .catch((err) => {
      console.error('SINGLE FILE ERROR', err);
      handleErrorMessageAPI(
        ERROR_MESSAGE.STREAM_ERROR_UPLOAD_FILE,
        ERROR_MESSAGE.ALERT_RED,
      );
      return err;
    });
};

/**
 * Upload files stacked into Amazon S3
 * @param {Array} files
 * @returns {Object}
 */
export const uploadFiles = (files = [], bucket = 'stockbit') =>
  streamApi.getUploadToken(bucket).then((res) => {
    const {
      data: { data: token },
    } = res;
    const collection = [];
    // eslint-disable-next-line no-unused-vars
    const mappingFiles = files.map((file) => {
      const uploadPromise = createUploadPromise(file, token, bucket);
      collection.push(uploadPromise);
      return file;
    });

    return Promise.all(collection)
      .then((data) => data)
      .catch((err) => console.error('MUNCUL ERROR', err));
  });
