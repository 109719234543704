export const DATA_CY_NAME_STREAM_HEADER_MENU = 'stream-header-menu';
export const DATA_CY_NAME_STREAM_SUB_HEADER_IDEA = 'stream-sub-header-idea';
export const DATA_CY_NAME_STREAM_SUB_HEADER_REPORT =
  'stream-sub-header-reports';
export const DATA_CY_NAME_STREAM_SUB_HEADER_PREDICTION =
  'stream-sub-header-predictions';
export const DATA_CY_NAME_STREAM_SUB_HEADER_POLLING =
  'stream-sub-header-polling';
export const DATA_CY_NAME_STREAM_SUB_HEADER_INSIDER =
  'stream-sub-header-insiders';
export const DATA_CY_NAME_STREAM_SUB_HEADER_CHART = 'stream-sub-header-charts';
export const DATA_CY_NAME_NEWS_HEADER_MENU = 'news-header-menu';
export const DATA_CY_NAME_RESEARCH_HEADER_MENU = 'research-header-menu';
export const DATA_CY_SEARCH_STREAM_MENU_FILTER = 'search-stream-menu-filter';
export const DATA_CY_SEARCH_STREAM_MENU = 'search-stream-menu';
export const DATA_CY_SEARCH_STREAM_MENU_SEARCH = 'search-stream-menu-search';
export const DATA_CY_SEARCH_STREAM_FILTER_FROM = 'search-stream-filter-from';
export const DATA_CY_SEARCH_STREAM_FILTER_TO = 'search-stream-filter-to';
export const DATA_CY_SEARCH_STREAM_SUBMIT = 'search-stream-submit';
export const DATA_CY_DROPDOWN_STREAM_ACTIVE = 'dropdown-stream-active';
export const DATA_CY_DROPDOWN_STREAM_TYPE_ALL = 'dropdown-stream-type-all';
export const DATA_CY_DROPDOWN_STREAM_TYPE_TRENDING =
  'dropdown-stream-type-trending';
export const DATA_CY_DROPDOWN_STREAM_TYPE_FOLLOWED =
  'dropdown-stream-type-followed';
export const DATA_CY_DROPDOWN_STREAM_TYPE_PEOPLE =
  'dropdown-stream-type-people';
export const DATA_CY_DROPDOWN_STREAM_TYPE_WATCHLIST =
  'dropdown-stream-type-watchlist';

export const DATA_CY_NAME_STREAM_HEADER_MENU_COMPANY =
  'stream-header-menu-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_IDEA_COMPANY =
  'stream-sub-header-idea-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_REPORT_COMPANY =
  'stream-sub-header-reports-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_PREDICTION_COMPANY =
  'stream-sub-header-predictions-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_POLLING_COMPANY =
  'stream-sub-header-polling-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_INSIDER_COMPANY =
  'stream-sub-header-insiders-company';
export const DATA_CY_NAME_STREAM_SUB_HEADER_CHART_COMPANY =
  'stream-sub-header-charts-company';
export const DATA_CY_NAME_NEWS_HEADER_MENU_COMPANY = 'news-header-menu-company';
export const DATA_CY_NAME_NOTES_MENU_COMPANY = 'stream-notes-compose-company';
export const DATA_CY_NAME_RESEARCH_HEADER_MENU_COMPANY =
  'research-header-menu-company';
export const DATA_CY_SEARCH_STREAM_MENU_FILTER_COMPANY =
  'search-stream-menu-filter-company';
export const DATA_CY_SEARCH_STREAM_MENU_SEARCH_COMPANY =
  'search-stream-menu-search-company';
export const DATA_CY_SEARCH_STREAM_FILTER_FROM_COMPANY =
  'search-stream-filter-from-company';
export const DATA_CY_SEARCH_STREAM_FILTER_TO_COMPANY =
  'search-stream-filter-to-company';
export const DATA_CY_SEARCH_STREAM_SUBMIT_COMPANY =
  'search-stream-submit-company';
export const DATA_CY_DROPDOWN_STREAM_TYPE_ALL_COMPANY =
  'dropdown-stream-type-all-company';
export const DATA_CY_DROPDOWN_STREAM_TYPE_TRENDING_COMPANY =
  'dropdown-stream-type-trending-company';
export const DATA_CY_DROPDOWN_STREAM_TYPE_FOLLOWED_COMPANY =
  'dropdown-stream-type-followed-company';
export const DATA_CY_DROPDOWN_STREAM_TYPE_PEOPLE_COMPANY =
  'dropdown-stream-type-people-company';
export const DATA_CY_DROPDOWN_STREAM_TYPE_WATCHLIST_COMPANY =
  'dropdown-stream-type-watchlist-company';
export const DATA_CY_DROPDOWN_STREAM_REPORT_ACTIVE =
  'dropdown-stream-report-active';

export const DATA_CY_USER_CATEGORY_IDEAS = 'user-category-ideas';
export const DATA_CY_USER_CATEGORY_REPLIES = 'user-category-replies';
export const DATA_CY_USER_CATEGORY_CHARTS = 'user-category-charts';
export const DATA_CY_USER_CATEGORY_LIKED = 'user-category-liked';
export const DATA_CY_USER_CATEGORY_SAVED = 'user-category-saved';
export const DATA_CY_USER_CATEGORY_PREDICTIONS = 'user-category-predictions';
export const DATA_CY_USER_CATEGORY_NOTES = 'user-category-notes';
