import {
  createSlice,
  createAsyncThunk,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import SUCCESS_MESSAGE from 'constants/successMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

// APIs
import settingApi from 'lib/api/setting';
import friendsApi from 'lib/api/friends';
import userApi from 'lib/api/user';
import tradingApi from 'lib/api/trade';

// Utils
import { uploadFiles } from 'utils/uploadFile';
import type { RootReducerState } from 'lib/rootReducer';
import {
  NotificationSettings,
  NotificationSettingsList,
} from '../../../@types/notifications';

// Initial state
const initialState = {
  getChangePasswordToken: {
    data: {
      change_token: '',
    },
    isLoading: false,
    message: '',
    error: null,
  },
  updatePassword: {
    data: {},
    isLoading: false,
    message: '',
    error: null,
  },
  blockedUsers: {
    data: [],
    isLoading: false,
    message: '',
    error: null,
    isLastPage: true,
  },
  notifications: {
    data: [],
    isLoading: false,
    message: '',
    error: null,
  },
  changePhone: {
    phone: '',
    successVerify: false,
    isLoading: false,
    error: null,
  },
  changeAvatar: {
    avatar: '',
    message: '',
    error: null,
    isLoading: false,
  },
  tradingAccounts: {
    data: {},
    message: '',
    error: null,
    isLoading: false,
  },
  avatar3DList: {
    data: [],
    message: '',
    error: null,
    isLoading: false,
  },
  invitationSetting: {
    data: {
      group_setting: '',
    },
    message: '',
    error: null,
    isLoading: false,
  },
  blacklistStatus: {
    status: false,
    message: '',
    error: null,
    isLoading: false,
  },
};

type EditProfileState = typeof initialState;

// Reducers
const reducers = {
  changePhoneNumber: (state: EditProfileState, action: PayloadAction<any>) => {
    state.changePhone.phone = action.payload;
  },
};

// Selectors
const editProfile = (state) => state.editProfile;
export const selectors = createSelector(
  editProfile,
  (profile: EditProfileState) => ({
    getChangePasswordToken: profile.getChangePasswordToken,
    updatePassword: profile.updatePassword,
    blockedUsers: profile.blockedUsers,
    notifications: profile.notifications,
    changePhone: profile.changePhone,
    avatar3DList: profile.avatar3DList,
    changeAvatar: profile.changeAvatar,
    tradingAccounts: profile.tradingAccounts,
    invitationSetting: profile.invitationSetting,
    blacklistStatus: profile.blacklistStatus,
  }),
);

// Action types
const CONTEXT = '@feature/edit-profile';

const actionType = {
  GET_CHANGE_PASSWORD_TOKEN: `${CONTEXT}/GET_CHANGE_PASSWORD_TOKEN`,
  UPDATE_PASSWORD: `${CONTEXT}/UPDATE_PASSWORD`,
  UPDATE_DISCOVERABLE_PRIVACY: `${CONTEXT}/UPDATE_DISCOVERABLE_PRIVACY`,
  GET_BLOCKED_USER: `${CONTEXT}/GET_BLOCKED_USER`,
  UNBLOCK_USER: `${CONTEXT}/UNBLOCK_USER`,
  DISCONNECT_SOCIAL_MEDIA: `${CONTEXT}/DISCONNECT_SOCIAL_MEDIA`,
  GET_NOTIFICATIONS: `${CONTEXT}/GET_NOTIFICATIONS`,
  UPDATE_NOTIFICATION: `${CONTEXT}/UPDATE_NOTIFICATION`,
  CHANGE_PHONE_NUMBER: `${CONTEXT}/CHANGE_PHONE_NUMBER`,
  VERIFY_PHONE_NUMBER: `${CONTEXT}/VERIFY_PHONE_NUMBER`,
  GET_AVATAR3D_LIST: `${CONTEXT}/GET_AVATAR3D_LIST`,
  CHANGE_AVATAR: `${CONTEXT}/CHANGE_AVATAR`,
  GET_TRADING_ACCOUNTS: `${CONTEXT}/GET_TRADING_ACCOUNTS`,
  UNLINK_TRADING_ACCOUNT: `${CONTEXT}/UNLINK_TRADING_ACCOUNT`,
  GET_INVITATION_SETTING: `${CONTEXT}/GET_INVITATION_SETTING`,
  UPDATE_INVITATION_SETTING: `${CONTEXT}/UPDATE_INVITATION_SETTING`,
  GET_BLOCKLIST_STATUS: `${CONTEXT}/GET_BLOCKLIST_STATUS`,
  UPDATE_BLOCKLIST_STATUS: `${CONTEXT}/UPDATE_BLOCKLIST_STATUS`,
};

// Helper
const handleUploadAvatar = async (file) => {
  let avatarUrl = '';
  try {
    const upload = await uploadFiles([file], 'avatar');

    if (upload.error) {
      throw new Error(upload);
    }

    const uploadedUrl = upload.map((res) => res.data.url);
    avatarUrl = uploadedUrl.join();
  } catch (error) {
    return { error };
  }

  return { data: avatarUrl };
};

// side effects
// TODO: Update all payload interface to extends CommonPayload from branch user profile page

interface updatePasswordParams {
  change_token: string;
  new: string;
  verify: string;
}
interface updateNotifParams {
  type: string | number;
  settings: {
    key: string;
    value: boolean;
  }[];
}

interface changePhoneParams {
  code: string;
  phone: string;
}

interface disconnectSocialMediaParams {
  socialMedia: string;
  password: string;
}
export const effects = {
  getChangePasswordToken: createAsyncThunk<any, string>(
    actionType.GET_CHANGE_PASSWORD_TOKEN,
    async (password) => {
      try {
        const requestTokenData = {
          password,
          req_type: 'CHANGE_TOKEN_REQ_TYPE_EDIT',
          type: 'CHANGE_TOKEN_TYPE_PASSWORD',
        };
        const response = await settingApi.getChangePasswordToken(
          requestTokenData,
        );

        if (!response.data) {
          throw new Error('Attempt to update password failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updatePassword: createAsyncThunk<any, updatePasswordParams>(
    actionType.UPDATE_PASSWORD,
    async (passwordData) => {
      try {
        const response = await settingApi.updatePassword(passwordData);

        if (!response.data) {
          throw new Error('Attempt to update password failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        handleErrorMessageAPI(
          SUCCESS_MESSAGE.PASSWORD_CHANGED,
          SUCCESS_MESSAGE.ALERT_GREEN,
        );

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updatePrivacy: createAsyncThunk<any>(
    actionType.UPDATE_DISCOVERABLE_PRIVACY,
    async () => {
      try {
        const response = await friendsApi.updateBlacklistStatus();

        if (!response.data) {
          throw new Error('Attempt to update discoverable privacy failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getBlockedUsers: createAsyncThunk<any, number>(
    actionType.GET_BLOCKED_USER,
    async (page) => {
      try {
        const response = await userApi.getBlockedUser(page);

        if (!response.data) {
          throw new Error('Attempt to get blocked user failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        const sanitizedData = data.blocked_list;

        return { data: sanitizedData, message, more: data.more };
      } catch (error) {
        return { error };
      }
    },
  ),
  unblockUser: createAsyncThunk<any, string>(
    actionType.UNBLOCK_USER,
    async (userid) => {
      try {
        const response = await userApi.unblockUser(userid);

        if (!response.data) {
          throw new Error('Attempt to unblock user failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

        return { data, message, userid };
      } catch (error) {
        return { error };
      }
    },
  ),
  disconnectSocialMedia: createAsyncThunk<any, disconnectSocialMediaParams>(
    actionType.DISCONNECT_SOCIAL_MEDIA,
    async ({ socialMedia, password }) => {
      try {
        const requestBody = {
          password,
        };
        const response = await userApi.disconnectSocialMedia(
          socialMedia,
          requestBody,
        );

        if (!response.data) {
          throw new Error('Attempt to disconnect social media failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getNotifications: createAsyncThunk<any>(
    actionType.GET_NOTIFICATIONS,
    async () => {
      try {
        const response = await settingApi.getNotificationSettings();

        if (!response.data) {
          throw new Error('Attempt to get user notifications failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updateNotification: createAsyncThunk<any, updateNotifParams>(
    actionType.UPDATE_NOTIFICATION,
    async (requestBody, { getState }) => {
      try {
        const response = await settingApi.updateNotificationSetting(
          requestBody,
        );

        if (!response.data) {
          throw new Error('Attempt to update notification setting failed');
        }

        const { data, error, message } = response.data;

        const {
          editProfile: {
            notifications: { data: notifData },
          },
        } = getState() as RootReducerState;

        const dataUpdated = notifData.map(
          (notifList: NotificationSettingsList) => {
            const currentData = notifList.notifications;

            return {
              ...notifList,
              notifications: currentData.map(
                (currNotifData: NotificationSettings) => {
                  if (currNotifData.type === data.type) {
                    return { ...data };
                  }
                  return currNotifData;
                },
              ),
            };
          },
        );

        if (error) {
          return { error, message };
        }

        return { message, dataUpdated };
      } catch (error) {
        return { error };
      }
    },
  ),
  // @ts-ignore
  getAvatar3DList: createAsyncThunk(actionType.GET_AVATAR3D_LIST, async () => {
    try {
      const response = await userApi.getAvatar3DList();

      if (!response.data) {
        throw new Error('Attempt to get avatar 3D list failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      return {
        data,
        message,
      };
    } catch (error) {
      return { error };
    }
  }),
  changeAvatar: createAsyncThunk<any, any>(
    actionType.CHANGE_AVATAR,
    async (file) => {
      try {
        const dataUpload = await handleUploadAvatar(file);
        const { error, data } = dataUpload;

        if (error) {
          return { error };
        }

        return { data };
      } catch (error) {
        return { error };
      }
    },
  ),
  getTradingAccounts: createAsyncThunk<any>(
    actionType.GET_TRADING_ACCOUNTS,
    async () => {
      try {
        const response = await tradingApi.getTradingConfig();

        if (!response.data) {
          throw new Error('Attempt to get trading config failed');
        }

        const { error, data, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  unlinkTradingAccount: createAsyncThunk<any, string>(
    actionType.UNLINK_TRADING_ACCOUNT,
    async (brokerKey) => {
      try {
        const response = await tradingApi.unlinkTradingAccount(brokerKey);

        if (!response.data) {
          throw new Error('Attempt to unlink trading account failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, brokerKey };
      } catch (error) {
        return { error };
      }
    },
  ),
  getInvitationSetting: createAsyncThunk<any>(
    actionType.GET_INVITATION_SETTING,
    async () => {
      try {
        const response = await settingApi.getInvitationSetting();

        if (!response.data) {
          throw new Error('Attempt to get invitation setting failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updateInvitationSetting: createAsyncThunk<any, string>(
    actionType.UPDATE_INVITATION_SETTING,
    async (groupSetting) => {
      try {
        const requestPayload = {
          group_setting: groupSetting,
        };
        const response = await settingApi.updateInvitationSetting(
          requestPayload,
        );

        if (!response.data) {
          throw new Error('Attempt to update invitation setting failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  getBlacklistStatus: createAsyncThunk<any>(
    actionType.GET_BLOCKLIST_STATUS,
    async () => {
      try {
        const response = await friendsApi.getBlacklistStatus();

        if (!response.data) {
          throw new Error('Attempt to get blacklist status failed');
        }

        const { error, error_type, message, data } = response.data;

        if (error || error_type) {
          throw new Error(message);
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  updateBlacklistStatus: createAsyncThunk<any>(
    actionType.UPDATE_BLOCKLIST_STATUS,
    async () => {
      try {
        const response = await friendsApi.updateBlacklistStatus();

        if (!response.data) {
          throw new Error('Attempt to set blacklist status failed');
        }

        const { error, error_type, message, data } = response.data;

        if (error || error_type) {
          throw new Error(message);
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.getChangePasswordToken.pending,
      (state: EditProfileState) => {
        state.getChangePasswordToken.isLoading = true;
        state.getChangePasswordToken.error = null;
      },
    )
    .addCase(
      effects.getChangePasswordToken.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.getChangePasswordToken.error = error;
        } else {
          state.getChangePasswordToken.data = data;
        }

        state.getChangePasswordToken.message = message;
        state.getChangePasswordToken.isLoading = false;
      },
    )
    .addCase(
      effects.getChangePasswordToken.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.getChangePasswordToken.isLoading = false;
        state.getChangePasswordToken.error = action.payload.error;
      },
    )

    .addCase(effects.updatePassword.pending, (state: EditProfileState) => {
      state.updatePassword.isLoading = true;
      state.updatePassword.error = null;
    })
    .addCase(
      effects.updatePassword.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.updatePassword.error = error;
        } else {
          state.updatePassword.data = data;
        }

        state.updatePassword.message = message;
        state.updatePassword.isLoading = false;
      },
    )
    .addCase(
      effects.updatePassword.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.updatePassword.isLoading = false;
        state.updatePassword.error = action.payload.error;
      },
    )
    // update discoverable privacy doesn't affect reducer
    .addCase(
      effects.getBlockedUsers.pending,
      (state: EditProfileState, action: PayloadAction<any, string, any>) => {
        state.blockedUsers.isLoading = true;
        state.blockedUsers.error = null;

        // reset list
        if (action.meta.arg === 1) {
          state.blockedUsers.data = initialState.blockedUsers.data;
        }
      },
    )
    .addCase(
      effects.getBlockedUsers.fulfilled,
      (state: EditProfileState, action: PayloadAction<any, string, any>) => {
        const { error, data, message, more } = action.payload;
        if (error) {
          state.blockedUsers.error = error;
        } else {
          state.blockedUsers.data.push(...data);
          state.blockedUsers.isLastPage = !more;
        }

        state.blockedUsers.isLoading = false;
        state.blockedUsers.message = message;
      },
    )
    .addCase(
      effects.getBlockedUsers.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.blockedUsers.error = action.payload.error;
        state.blockedUsers.isLoading = false;
      },
    )
    // only handle if unblock success
    .addCase(
      effects.unblockUser.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, userid } = action.payload;

        if (!error) {
          const newUserList = state.blockedUsers.data.filter(
            (user) => user.user_id !== userid,
          );
          state.blockedUsers.data = newUserList;
        }
      },
    )
    .addCase(effects.getNotifications.pending, (state: EditProfileState) => {
      state.notifications.isLoading = true;
      state.notifications.error = null;
    })
    .addCase(
      effects.getNotifications.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, message, data } = action.payload;
        if (error) {
          state.notifications.error = error;
        } else {
          state.notifications.data = data;
        }

        state.notifications.message = message;
        state.notifications.isLoading = false;
      },
    )
    .addCase(
      effects.getNotifications.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.notifications.error = action.payload.error;
        state.notifications.isLoading = false;
      },
    )
    .addCase(
      effects.updateNotification.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, dataUpdated } = action.payload;

        if (error) {
          state.notifications.error = error;
        } else {
          state.notifications.data = dataUpdated;
        }
      },
    )
    .addCase(
      effects.changeAvatar.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { data, error } = action.payload;

        if (!error) {
          state.changeAvatar.avatar = data;
        }
      },
    )
    .addCase(effects.getTradingAccounts.pending, (state: EditProfileState) => {
      state.tradingAccounts.isLoading = true;
      state.tradingAccounts.error = null;
    })
    .addCase(
      effects.getTradingAccounts.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { data, error, message } = action.payload;

        if (error) {
          state.tradingAccounts.error = error;
        } else {
          state.tradingAccounts.data = data;
        }

        state.tradingAccounts.message = message;
        state.tradingAccounts.isLoading = false;
      },
    )
    .addCase(
      effects.getTradingAccounts.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.tradingAccounts.error = action.payload.error;
        state.tradingAccounts.isLoading = false;
      },
    )
    .addCase(
      effects.unlinkTradingAccount.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, brokerKey } = action.payload;
        if (!error) {
          // @ts-ignore
          const brokerList = state.tradingAccounts.data?.brokers?.list || [];
          const brokerIdx = brokerList.findIndex(
            (broker) => broker.key === brokerKey,
          );

          if (brokerIdx > -1) {
            // @ts-ignore
            state.tradingAccounts.data.brokers.list[brokerIdx].link = null;
          }
        }
      },
    )
    .addCase(
      effects.getAvatar3DList.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { data, error, message } = action.payload;

        if (error) {
          state.avatar3DList.error = error;
        } else {
          state.avatar3DList.data = data;
        }

        state.avatar3DList.message = message;
        state.avatar3DList.isLoading = false;
      },
    )

    .addCase(
      effects.getInvitationSetting.pending,
      (state: EditProfileState) => {
        state.invitationSetting.isLoading = true;
        state.invitationSetting.error = null;
      },
    )
    .addCase(
      effects.getInvitationSetting.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.invitationSetting.error = error;
        } else {
          state.invitationSetting.data = data;
        }

        state.invitationSetting.message = message;
        state.invitationSetting.isLoading = false;
      },
    )
    .addCase(
      effects.getInvitationSetting.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.invitationSetting.isLoading = false;
        state.invitationSetting.error = action.payload.error;
      },
    )

    .addCase(
      effects.updateInvitationSetting.pending,
      (state: EditProfileState) => {
        state.invitationSetting.isLoading = true;
        state.invitationSetting.error = null;
      },
    )
    .addCase(
      effects.updateInvitationSetting.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;
        if (error) {
          state.invitationSetting.error = error;
        } else {
          state.invitationSetting.data = data;
        }

        state.invitationSetting.message = message;
        state.invitationSetting.isLoading = false;
      },
    )
    .addCase(
      effects.updateInvitationSetting.rejected,
      (state: EditProfileState, action: PayloadAction<any>) => {
        state.invitationSetting.isLoading = false;
        state.invitationSetting.error = action.payload.error;
      },
    )
    .addCase(
      effects.getBlacklistStatus.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;

        if (!error) {
          state.blacklistStatus.status = data.status;
        }

        state.blacklistStatus.message = message;
      },
    )
    .addCase(
      effects.updateBlacklistStatus.fulfilled,
      (state: EditProfileState, action: PayloadAction<any>) => {
        const { error, data, message } = action.payload;

        if (!error) {
          state.blacklistStatus.status = data.status;
        }

        state.blacklistStatus.message = message;
      },
    );
};

const editProfileSlice = createSlice({
  name: 'editProfile',
  initialState,
  reducers,
  extraReducers,
});

export default editProfileSlice;
