import color from '@deprecated-ui/_style/color';
import * as DataCy from './Navigation/NavigationTab/__tests__/constant';

const STREAM_TYPE = {
  GENERIC: 'generic',
  USER: 'user',
  SYMBOL: 'symbol',
};

const genericCategories = [
  {
    label: 'Ideas',
    key: 'ideas',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_IDEA,
  },
  {
    label: 'Reports',
    key: 'reports',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_REPORT,
  },
  {
    label: 'Predictions',
    key: 'predictions',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_PREDICTION,
  },
  {
    label: 'Polling',
    key: 'pollings',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_POLLING,
  },
  {
    label: 'Insiders',
    key: 'insider',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_INSIDER,
  },
  {
    label: 'Charts',
    key: 'charts',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_CHART,
  },
];

const symbolCategories = [
  {
    label: 'All',
    key: 'all',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_ALL_COMPANY,
  },
  {
    label: 'Notes',
    key: 'notes',
    dataCy: DataCy.DATA_CY_NAME_NOTES_MENU_COMPANY,
  },
  {
    label: 'News',
    key: 'news',
    dataCy: DataCy.DATA_CY_NAME_NEWS_HEADER_MENU_COMPANY,
  },
  {
    label: 'Reports',
    key: 'reports',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_REPORT_COMPANY,
  },
  {
    label: 'Research',
    key: 'research',
    dataCy: DataCy.DATA_CY_NAME_RESEARCH_HEADER_MENU_COMPANY,
  },
  {
    label: 'Ideas',
    key: 'ideas',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_IDEA_COMPANY,
  },
  {
    label: 'Predictions',
    key: 'predictions',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_PREDICTION_COMPANY,
  },
  {
    label: 'Polling',
    key: 'pollings',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_POLLING_COMPANY,
  },
  {
    label: 'Insiders',
    key: 'insider',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_INSIDER_COMPANY,
  },
  {
    label: 'Charts',
    key: 'charts',
    dataCy: DataCy.DATA_CY_NAME_STREAM_SUB_HEADER_CHART_COMPANY,
  },
];

const userCategories = [
  {
    label: 'Ideas',
    key: 'main ideas',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_IDEAS,
  },
  {
    label: 'Notes',
    key: 'notes',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_NOTES,
  },
  {
    label: 'Replies',
    key: 'reply',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_REPLIES,
  },
  {
    label: 'Charts',
    key: 'charts',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_CHARTS,
  },
  {
    label: 'Liked',
    key: 'liked',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_LIKED,
  },
  {
    label: 'Saved',
    key: 'saved',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_SAVED,
  },
  {
    label: 'Predictions',
    key: 'predictions',
    dataCy: DataCy.DATA_CY_USER_CATEGORY_PREDICTIONS,
  },
];

const categories = {
  [STREAM_TYPE.GENERIC]: genericCategories,
  [STREAM_TYPE.SYMBOL]: symbolCategories,
  [STREAM_TYPE.USER]: userCategories,
};

const streamTypes = [
  {
    label: 'Trending',
    key: 'trending',
    lsKey: 'trending',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_TRENDING,
  },
  {
    label: 'Followed',
    key: 'all watchlist',
    lsKey: 'following',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_FOLLOWED,
  },
  {
    label: 'All',
    key: 'latest',
    lsKey: 'latest',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_ALL,
  },
  {
    label: 'People',
    key: 'people watchlist',
    lsKey: 'following_people',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_PEOPLE,
  },
  {
    label: 'Watchlist',
    key: 'company watchlist',
    lsKey: 'following_company',
    dataCy: DataCy.DATA_CY_DROPDOWN_STREAM_TYPE_WATCHLIST,
  },
];

const reportCategories = [
  {
    label: 'All',
    key: 'all',
  },
  {
    label: 'Laporan Keuangan',
    key: 'laporan_keuangan',
  },
  {
    label: 'RUPS/Public Expose',
    key: 'rups',
  },
  {
    label: 'Kepemilikan Saham',
    key: 'kepemilikan_saham',
  },
  {
    label: 'Dividend',
    key: 'dividend',
  },
  {
    label: 'Corporate Action',
    key: 'corporate_action',
  },
  {
    label: 'Others',
    key: 'others',
  },
];

const reportOptions = [
  { key: '1', label: 'Improper Use of $ Tag' },
  { key: '2', label: 'Incorrect Writing Standard' },
  { key: '3', label: 'Advertising' },
  { key: '4', label: 'Junk Post' },
  { key: '5', label: 'Spam' },
  { key: '6', label: 'Out of Topic' },
  { key: '7', label: 'Vulgarity' },
  { key: '8', label: 'Abusive Behaviour' },
  { key: '9', label: 'Provoking Other Users' },
  { key: '10', label: 'Inapropriate Username & Photo' },
  { key: '11', label: 'Stocks Cheering' },
];

const stockChange = {
  netral: {
    icon: '',
    color: color.nobel,
  },
  up: {
    icon: 'icon-symbol_up',
    color: color.primary,
  },
  down: {
    icon: 'icon-symbol_down',
    color: color.sunsetOrange,
  },
};

const STREAM_STATE = {
  CATEGORY: 'stbx-active-stream-filter',
  TYPE: 'stbx-active-stream',
  TAB: 'stbx-active-stream-header',
};

const nonSourceNews = {
  stockbitnews: {
    username: 'StockbitNews',
    label: 'Stockbit News',
    extend: true,
    image: '/img/sbnewsicon.png',
  },
  klsenews: {
    username: 'KLSEnews',
    label: 'KLSENews',
    extend: false,
    image: '/img/sbnewsicon.png',
  },
};

const STREAM_TABS = [
  {
    label: 'Stream',
    value: 'stream',
    icon: '/icon/stream-filter/header-stream',
    dataCy: DataCy.DATA_CY_NAME_STREAM_HEADER_MENU,
  },
  {
    label: 'News',
    value: 'news',
    icon: '/icon/stream-filter/header-news',
    dataCy: DataCy.DATA_CY_NAME_NEWS_HEADER_MENU,
  },
  {
    label: 'Research',
    value: 'research',
    icon: '/icon/stream-filter/header-research',
    dataCy: DataCy.DATA_CY_NAME_RESEARCH_HEADER_MENU,
  },
];

const STREAM_TABS_MY = [
  {
    label: 'Stream',
    value: 'stream',
    icon: '/icon/stream-filter/header-stream',
    dataCy: DataCy.DATA_CY_NAME_STREAM_HEADER_MENU,
  },
];

const DEFAULT_TYPE = 'ideas';
const DEFAULT_HEADER = 'stream';

const MAX_TIPPING_AMOUNT = 10000000;

const LS_STREAM_TRENDING_TAB_DATE = 'stbx-active-trending-date';

// Constants for stream v3 (general, company, user)
const STREAM_VERSION = {
  DEFAULT: 'exodus',
  V3: 'exodus_v3',
};

const STREAM_CATEGORY_ENUM = {
  ALL: 'STREAM_CATEGORY_ALL',
  ALL_WATCHLIST: 'STREAM_CATEGORY_ALL_WATCHLIST',
  PEOPLE_WATCHLIST: 'STREAM_CATEGORY_PEOPLE_WATCHLIST',
  COMPANY_WATCHLIST: 'STREAM_CATEGORY_COMPANY_WATCHLIST',
  NEWS: 'STREAM_CATEGORY_NEWS',
  CHART: 'STREAM_CATEGORY_CHART',
  CHARTS: 'STREAM_CATEGORY_CHART',
  REPORTS: 'STREAM_CATEGORY_REPORTS',
  IDEAS: 'STREAM_CATEGORY_IDEAS',
  INSIDER: 'STREAM_CATEGORY_INSIDER',
  PREDICTION: 'STREAM_CATEGORY_PREDICTION',
  PREDICTIONS: 'STREAM_CATEGORY_PREDICTION',
  POLLING: 'STREAM_CATEGORY_POLLING',
  POLLINGS: 'STREAM_CATEGORY_POLLING',
  REPLY: 'STREAM_CATEGORY_REPLY',
  MAIN_IDEAS: 'STREAM_CATEGORY_MAIN_IDEAS',
  LIKED: 'STREAM_CATEGORY_LIKED',
  SAVED: 'STREAM_CATEGORY_SAVED',
  RESEARCH: 'research',
};

const TAB_CATEGORIES = {
  all: STREAM_CATEGORY_ENUM.ALL,
  ideas: STREAM_CATEGORY_ENUM.IDEAS,
  news: STREAM_CATEGORY_ENUM.NEWS,
  reports: STREAM_CATEGORY_ENUM.REPORTS,
  charts: STREAM_CATEGORY_ENUM.CHART,
  insider: STREAM_CATEGORY_ENUM.INSIDER,
  predictions: STREAM_CATEGORY_ENUM.PREDICTION,
  pollings: STREAM_CATEGORY_ENUM.POLLING,
  latest: STREAM_CATEGORY_ENUM.IDEAS,
  'all watchlist': STREAM_CATEGORY_ENUM.ALL_WATCHLIST,
  'people watchlist': STREAM_CATEGORY_ENUM.PEOPLE_WATCHLIST,
  'company watchlist': STREAM_CATEGORY_ENUM.COMPANY_WATCHLIST,
};

const TAB_USER_CATEGORIES = {
  'main ideas': STREAM_CATEGORY_ENUM.MAIN_IDEAS,
  reply: STREAM_CATEGORY_ENUM.REPLY,
  charts: STREAM_CATEGORY_ENUM.CHART,
  liked: STREAM_CATEGORY_ENUM.LIKED,
  saved: STREAM_CATEGORY_ENUM.SAVED,
  predictions: STREAM_CATEGORY_ENUM.PREDICTIONS,
};

const STREAM_REPORT_TYPE_ENUM = {
  OTHER: 'REPORT_TYPE_OTHER',
  LAPORAN_KEUANGAN: 'REPORT_TYPE_LAPORAN_KEUANGAN',
  RUPS: 'REPORT_TYPE_RUPS',
  KEPIMILIKAN_SAHAM: 'REPORT_TYPE_KEPIMILIKAN_SAHAM',
  DIVIDEN: 'REPORT_TYPE_DIVIDEN',
  CORPORATE_ACTION: 'REPORT_TYPE_CORPORATE_ACTION',
  ALL: 'REPORT_TYPE_ALL',
};

const STREAM_REPORT = {
  all: STREAM_REPORT_TYPE_ENUM.ALL,
  laporan_keuangan: STREAM_REPORT_TYPE_ENUM.LAPORAN_KEUANGAN,
  rups: STREAM_REPORT_TYPE_ENUM.RUPS,
  kepemilikan_saham: STREAM_REPORT_TYPE_ENUM.KEPIMILIKAN_SAHAM,
  dividend: STREAM_REPORT_TYPE_ENUM.DIVIDEN,
  corporate_action: STREAM_REPORT_TYPE_ENUM.CORPORATE_ACTION,
  others: STREAM_REPORT_TYPE_ENUM.OTHER,
};

export {
  categories,
  streamTypes,
  reportOptions,
  stockChange,
  STREAM_TYPE,
  reportCategories,
  STREAM_STATE,
  STREAM_TABS,
  STREAM_TABS_MY,
  nonSourceNews,
  DEFAULT_TYPE,
  DEFAULT_HEADER,
  MAX_TIPPING_AMOUNT,
  LS_STREAM_TRENDING_TAB_DATE,
  STREAM_VERSION,
  STREAM_CATEGORY_ENUM,
  STREAM_REPORT,
  STREAM_REPORT_TYPE_ENUM,
  TAB_CATEGORIES,
  TAB_USER_CATEGORIES,
};
