export type TypeFilter = 'SUGGEST' | 'CONTACT' | 'SEARCH' | 'TRENDING';

export enum TrendingTimeFrame {
  ALL = 'TIME_FRAME_ALL',
  ONE_WEEK = 'TIME_FRAME_1W',
  ONE_MONTH = 'TIME_FRAME_1M',
}

export interface DiscoverFilter {
  label: string;
  value: TypeFilter;
}

export interface ToggleFollowerUserTypes {
  userid: number | string;
  isFollowed: boolean;
  index: number;
}
